import loading from '../src/source/loading.webp' 
import { Unity, useUnityContext} from "react-unity-webgl";

import { useState, useEffect } from 'react'
import { ethers } from 'ethers'



const ABI = [
  'function claim(address receiver, uint256 quantity, address currency, uint256 pricePerToken, bytes32[] proofs,uint256 proofMaxQuantityPerTransaction) payable',
  'function balanceOf(address account) external view returns (uint256)'
]


export default function Home() {

const [shownAddress, setShownAddress] = useState("Connect")
const [userAddress, setUserAddress] = useState(1000)
const [shouldReload, setReload] = useState(false)
const [userBalance, setUserBalance] = useState(0)

const [style, setStyle] = useState({"width" : "1120px", "height" : "650px"})

const provider = new ethers.providers.JsonRpcProvider("https://opt-mainnet.g.alchemy.com/v2/q4ifBT2BtbUc_mFfGOhM3pXjgKxlDVGP")
const contract = new ethers.Contract("0x0a71bAb4673A408465e33B638d41e96F4f1652c4", ABI, provider)

useEffect(()=>
{
  const interval = setInterval(() => {
    try{
      const getBalance= async () => 
      {
        const ts = await contract.balanceOf(userAddress)
        setUserBalance(ts.toString())
      }
      getBalance()}catch{}


      if(window.matchMedia('(min-width: 1900px)').matches){
        setStyle({"width" : "1120px", "height" : "650px"})
      } 
      else if(window.matchMedia('(min-width: 1200px)').matches){
        setStyle({"width" : "960px", "height" : "600px"})
      } 
      else if(window.matchMedia('(min-width:800px)').matches){
        setStyle({"width" : "800px", "height" : "500px"})
      } 
      else if(window.matchMedia('(min-width: 640px)').matches){
        setStyle({"width" : "640px", "height" : "400px"})
      }
      else if(window.matchMedia('(min-width: 340px)').matches){
        setStyle({"width" : "100%", "height" : "100%"})
      }

  },5000)


},[shouldReload])



const reloadEffect = () => {
  setReload(!shouldReload)
}


const connectButton = async ()  => {
 try{
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
  await provider.send("eth_requestAccounts", [])
  const signer = provider.getSigner()
  const address = await signer.getAddress()
  setUserAddress(address)
  setShownAddress((await signer.getAddress()).toString().substring(0,8) + "..")
  reloadEffect()
  provider.provider.on("accountsChanged", (accounts) => {

    setShownAddress(accounts[0].toString().substring(0,8) + "..")
    setUserAddress(accounts[0])
  })
}catch(err){
  console.log(err)
  window.alert("You have to install metamask first")
}

}


const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
  loaderUrl: "game/WebGLTest.loader.js",
  dataUrl: "game/WebGLTest.data",
  frameworkUrl: "game/WebGLTest.framework.js",
  codeUrl: "game/WebGLTest.wasm",
});




return (
    <main class='scroll-smooth min-h-screen font-bold'>

      
        <title>Rocket Picklez</title>
          <div class="h-10v">
            <div class="max-w-max mx-auto xsm1:mx-0">
              <div>
                <h1 id="title" class='font-poppins text-white xsm1:mb-5 tracking-wider text-center leading-tight xsm1:leading-normal mt-6 xsm1:mt-0'>Rocket Picklez</h1>
                    <a href='https://www.tickledpicklez.fun'>
                      <button id="backbutton" class='font-poppins text-white bg-pickle-purple 
                      border-black border-2
                      hover:brightness-85
                      max-w-max w-auto rounded-2xl
                      tracking-wide px-6 xsm1:px-2
                      mt-4 xsm1:mt-0'>go home
                      </button>
                    </a>
              </div>
              <button 
                        class='font-poppins xmb:absolute right-[5%] top-[5%] text-white bg-pickle-blue 
                        border-black border-2
                        hover:brightness-85
                        max-w-max w-auto rounded-2xl
                        tracking-wide px-6'
                        id="connect" onClick={connectButton}>
                        {shownAddress}
              </button> 
            </div>

            <div class='xmb:w-full xmb:absolute mt-12 w-[50%] mx-auto max-w-max xmb:max-w-none top-[25%]'>
              <div class='max-w-max mx-auto'>                           
                  { userBalance > 0 ?         
                  <div>            
                      {loadingProgression === 1 ?
                      <></>
                      : 
                      <div>
                          <p id="stroke" class="font-poppins text-white xsm1:mb-5 tracking-wider text-center">Game is loading...</p>
                          <img alt="Tickled Picklez NFT Optimism" class="block rounded-3xl mx-auto max-w-max w-[40vw] xmb:w-[12vw]" id="imgtrf" src={loading}></img> 
                      </div>
                  }                 
                        <Unity
                        className='max-w-max mx-auto'
                        unityProvider={unityProvider}
                        style={style}/>                                                                                             
                  </div>
                  : 
                   <div></div>
                  }
                  { userAddress.length > 0 ?
                      <div>
                     { userBalance !== 0 ?
                        <p id="stroke" class="font-poppins text-white xsm1:mb-5 tracking-wider text-center mt-6">You have <span id="highlight" class="text-pickle-green">
                        {userBalance}</span> picklez</p> : 
                        <div>
                          <p id="stroke" class="font-poppins text-white xsm1:mb-5 tracking-wider text-center mt-6">Finding your picklez...</p>
                          <img alt="Tickled Picklez NFT Optimism" class="block rounded-3xl mx-auto max-w-max w-[40vw] xmb:w-[12vw]" id="imgtrf" src={loading}></img> 
                        </div>
                      }
                      </div>
                      :

                      <div>
                      <p id="stroke" class="font-poppins text-white xsm1:mb-5 tracking-wider text-center">Connect your wallet first</p>
                      <img alt="Tickled Picklez NFT Optimism" class="block rounded-3xl mx-auto max-w-max w-[40vw] xmb:w-[12vw]" id="imgtrf" src={loading}></img> 
                      </div>
                    }  
              </div>
            </div>     
          </div>        
    </main> 
  )
}
